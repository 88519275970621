import React from 'react'
import { Link } from 'gatsby'
import Image from './image'
import '../styles/project-card.less'

export const ProjectTypes = {
  SHOW: 'SHOW',
  WORK: 'WORK'
}

export default class ProjectCard extends React.Component {
  state = {
    forceBreakHalf: false,
    forceBreakLines: false
  }
  relayout() {
    let forceBreakLines = false
    let forceBreakHalf = false

    if (this._container && this._textContainer) {
      if (this._container.clientWidth <= 800) forceBreakHalf = true
      if (this._container.clientWidth <= 640) forceBreakLines = true      
    }

    if (this.state.forceBreakHalf != forceBreakHalf || this.state.forceBreakLines != forceBreakLines)
      this.setState({ forceBreakLines, forceBreakHalf })
  }
  componentDidMount() {
    this.relayout()
  }
  componentDidUpdate() {
    this.relayout()
  }
  render() {
    const { image, permalink, title, institution, dates, location, medium, dimensions, type } = this.props

    const hasMedium = medium && (medium != '')
    const hasDimensions = dimensions && (dimensions != '')
    const { forceBreakLines, forceBreakHalf } = this.state

    return (
      <article className="show-card" ref={(s) => this._container = s}>
        <Link to={permalink}>
          <Image imgSharp={image.childImageSharp} publicURL={image.publicURL}/>
        </Link>
        <figcaption className="project-info">
          { type == ProjectTypes.SHOW &&
            <>
              <div className="project-info-text-container" ref={(s) => this._textContainer = s}> 
                <span className="title"><Link to={permalink}>{title}</Link></span>{ forceBreakLines && <br/> }
                <span className="institution"> {institution}</span>{!forceBreakLines && <span>, </span>}{ forceBreakHalf && <br/> }
                <span className="dates">{dates}</span>
                { location && location != '' && <span className="location-comma">{!forceBreakLines && <span>, </span>}{ forceBreakLines && <br/> }<span className="location">{location}</span></span> }
              </div>
            </>
          }
          { type == ProjectTypes.WORK &&
            <>
              <div className="project-info-text-container" ref={(s) => this._textContainer = s}> 
                <span className="title"><Link to={permalink}>{title}</Link></span>
                { forceBreakLines && <br/> }              
                <span className="dates"> {dates}</span>
                { hasMedium && (<span className="project-info-text-container">{!forceBreakLines && <span>, </span>}{ forceBreakHalf && <br/> }<span className="medium">{medium}</span></span>) } 
                { hasDimensions && (<span className="project-info-text-container">{!forceBreakLines && <span>, </span>}{ forceBreakLines && <br/> }<span className="dimensions">{dimensions}</span></span>) }
              </div>
            </>
          }
        </figcaption>
      </article>
    )
  }
}

ProjectCard.defaultProps = {
  image: null,
  permalink: '/',
  title: null,
  institution: null,
  dates: null,
  location: null,
  medium: null,
  type: ProjectTypes.SHOW
}