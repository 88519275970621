import React from 'react'
import { graphql } from 'gatsby'
import ProjectCard, { ProjectTypes } from '../components/projectCard'
import Footer from '../components/footer'
import { withMainContext } from '../components/menuContainer'

class Template extends React.Component {
  componentDidMount() {
    const { setLargeMenuRatioIfUnchanged } = this.props
    if (setLargeMenuRatioIfUnchanged) setLargeMenuRatioIfUnchanged()
  }
  render () {    
    const { data, pageContext } = this.props
    const allNodes = data.allMarkdownRemark.edges  
    const order = pageContext.order.data.markdownRemark.frontmatter.items

    let titleMap = order.reduce((acc, el, index) => {
      acc[el.relation] = index
      return acc
    }, {})

    let orderedNodes = allNodes
      .filter(n => (n.node.frontmatter.title in titleMap))
      .sort((a, b) => (titleMap[a.node.frontmatter.title] - titleMap[b.node.frontmatter.title]))

    return (
      <section>
        <section className="all-shows-container">
          { orderedNodes.map(n => {
            const show = n.node
            return (
              <ProjectCard 
                key={show.id}
                type={ProjectTypes.SHOW}
                isShow={true}
                permalink={show.fields.permalink}
                image={show.frontmatter.thumbnail}
                title={show.frontmatter.title}
                dates={show.frontmatter.dates}
                institution={show.frontmatter.institution}
                location={show.frontmatter.location}
              />
            )
          })}
        </section>
        <Footer lastEdited={allNodes[0].node.frontmatter.lastEdited}/>
      </section>
    )
  }
}

export default withMainContext((context, props) => ({
  setLargeMenuRatioIfUnchanged: context.action.setLargeMenuRatioIfUnchanged
}))(Template)

export const allShowsQuery = graphql`
  query AllShowsPage {
    allMarkdownRemark(filter: {frontmatter: {layout: {eq: "shows"}}}) {
      edges {
        node {
          id
          fields {
            permalink
          }
          frontmatter {       
            lastEdited     
            title,
            institution,
            location,
            dates,
            thumbnail {
              childImageSharp {
                fluid(quality: 75, maxHeight: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
              size  
            }
          }
        }
      }
    }
  }
`