import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { withMainContext } from './menuContainer'
import { navigateToTop } from '../components/constants'

class Footer extends React.Component {
  render() {
    const { lastEdited } = this.props
    return (
      <footer>
        { lastEdited && 
          <>
            Last edited: {lastEdited}
          </>
        }    
      </footer>    
    )
  }
}

export default withMainContext((context, props) => ({
  lastEdited: context.lastEdited  
}))(Footer)

Footer.propTypes = {
  lastEdited: PropTypes.string,
}
