import React from "react"
import PropTypes from 'prop-types'
import Img from "gatsby-image"
import classnames from 'classnames'
import '../styles/image.less'

const Image = ({ credits, imageInfo, imgSharp, publicURL, marginBottom }) => {
  const containerCls = classnames({
    'image-container': true,
    'margin-bottom': marginBottom
  })
  if (!imgSharp && !publicURL) return null
  const hasImageInfo = (credits) || (imageInfo && imageInfo.length >= 1)
  return (
    <figure className={containerCls}>
      { imgSharp && <Img className="image-wrapper" fluid={imgSharp.fluid} imgStyle={{ objectFit: 'contain' }} loading="eager" /> }
      { !imgSharp && publicURL && 
        <div className="image-wrapper custom-image-wrapper">
          <img className="custom-image" src={publicURL}></img>
        </div>
      }

      { hasImageInfo && 
        <figcaption className="image-info">
          { credits && <div className="color-gray image-credits">{credits}</div>}

          {/* { imageInfo && imageInfo.length == 1 &&
            <>
              <span className="color-black">{imageInfo[0].workTitle}</span>
              { imageInfo[0].additionalInfo && <span className="color-gray">, {imageInfo[0].additionalInfo}</span> }
              { credits && <>, <span className="color-gray">{credits}</span></>}
            </>
          } */}
          { imageInfo && imageInfo.length >= 1 &&
            <ul>
              { imageInfo.map(i => {
                return (
                  <li>
                    <span className="color-black">{i.workTitle}</span>
                    { i.additionalInfo && (i.additionalInfo != '') && <span className="color-gray">, {i.additionalInfo}</span> }
                  </li>
                  )
                })
              }
            </ul>
          }
        </figcaption>
      }
      {/* { credits && <div class="image-credits">{credits}</div> } */}
      {/* { caption && <div class="image-caption" dangerouslySetInnerHTML={{ __html: caption }}></div> } */}
    </figure>
  )
}

Image.propTypes = {
  caption: PropTypes.string,
  credits: PropTypes.string,
  imgSharp: PropTypes.object
}

Image.defaultProps = {
  caption: null,
  credits: null,
  imgSharp: null
}

export default Image
